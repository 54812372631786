.position-card {
  width: 330px;
  margin-bottom: 16px;
  padding: 3px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1), 0 0 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.position-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.position-header h3 {
  margin-bottom: 8px;
  font-size: 14px;
  position: relative;
}

.switch-wrapper {
  margin-left: 8px;
}

.position-card .ant-switch {
  margin-left: auto;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-top: 5px;
  margin-bottom: 15px;
}

.position-card.short {
  border: 2px solid rgba(255, 0, 0, 0.5);
}

.position-card.flat {
  border: 2px solid rgba(0, 0, 0, 0.5);
}

.position-card.long {
  border: 2px solid rgba(0, 128, 0, 0.5);
}

.position-card p {
  margin: 4px 0;
}

.position-card .price {
  color: green;
  font-weight: bold;
}

.position-card .pnl {
  font-weight: bold;
}

.position-card .pnl.red {
  color: rgba(255, 0, 0, 0.8);
}

.position-card .pnl.green {
  color: rgba(0, 128, 0, 0.8);
}

.bold-key {
  font-weight: bold;
  color: #333333;
}
.action-wrapper {
  display: flex;
  margin-top: 8px;
}

.action-wrapper > :not(:last-child) {
  margin-right: 8px;
  margin-bottom: 8px;
}

/* For LONG button */
.action-button-long {
  background-color: green;
  color: white;
  font-weight: bold;
}

/* For SHORT button */
.action-button-short {
  background-color: red;
  color: white;
  font-weight: bold;
}

/* For CLOSE button */
.action-button-close {
  background-color: black;
  color: white;
  font-weight: bold;
}
.action-button-delete {
  font-weight: bold;
}
