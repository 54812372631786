.App {
  text-align: center;
}

.appLayout {
  margin: 10px; /* Adjust this value as needed */
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 40px); /* Subtracting double the margin */
}

.header {
  width: 100%;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  margin: 0px;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;  
}

.content {
  flex-grow: 1;
  border-left: 1px solid #000; /* Change #000 to the color you want */
  border-right: 1px solid #000; /* Change #000 to the color you want */
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  width: 100%;
  text-align: center;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}



