.positions-action-wrapper {
    display: flex;
    margin: 8px;
    margin-top: 16px;
    margin-left: 16px;
}

.positions-action-wrapper> :not(:last-child) {
    margin-right: 8px;
    margin-bottom: 8px;

}

.positions-action-button-new {
    font-weight: bold;
}

.position-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin: 16px;
}