
.login-wrapper {
  width: 330px;
  margin: 0 auto; /* Center horizontally */
  margin-top: 20vh; /* Center vertically */
  padding: 3px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1), 0 0 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
}
